import React from 'react'
import RegularTypo from '../../../typography/RegularTypo'
import TinyCapsTypo from '../../../typography/RegularTypo'
import { borderGray, mainBackgroundGray } from '../../../utils/theme'

export default function SubMenu({ text, tiny }) {
  return (
    <div
      style={{
        display: 'flex',
        padding: 16,
        borderTop: `1px solid ${borderGray}`,
        background: tiny && mainBackgroundGray,
      }}
    >
      {!tiny ? <RegularTypo text={text} /> : <TinyCapsTypo text={text} />}
    </div>
  )
}
