import React from 'react'

const CloseX = ({ onClick }) => {
  return (
    <button
      style={{
        padding: 0,
        border: 0,
        cursor: 'pointer',
        marginTop: '-10px',
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
      }}
      onClick={() => onClick()}
      onKeyPress={() => onClick()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.556"
        height="15.556"
        viewBox="0 0 15.556 15.556"
      >
        <g
          id="Group_10726"
          data-name="Group 10726"
          transform="translate(-3850 -5368)"
        >
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="20"
            height="2"
            transform="translate(3851.414 5368) rotate(45)"
            fill="#b3b3b3"
          />
          <rect
            id="Rectangle_10"
            data-name="Rectangle 10"
            width="20"
            height="2"
            transform="translate(3850 5382.142) rotate(-45)"
            fill="#b3b3b3"
          />
        </g>
      </svg>
    </button>
  )
}

export default CloseX
