import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import { Location } from '@reach/router'
import { useQueryParam, StringParam } from 'use-query-params'
import MenuButton from '../atoms/MenuButton'
import RegularTypo from '../typography/RegularTypo'
import styled from 'styled-components'


const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 4px;
  color: #868686;
`

const LanguageSwitcher = () => {

  const [filter] = useQueryParam('filter', StringParam)
  const parameter = filter ? `/?filter=${filter}` : ''
  const { t, i18n } = useTranslation()
  const langu = [
    {
      name: 'Deutsch',
      code: 'De'
    },
    {
      name: 'English',
      code: 'En'
    },
    {
      name: 'Nederlands',
      code: 'Nl'
    },
  ]

  const removeTrailingSlash = (path) => {
    if (path.substring(path.length - 1) === "/") {
      path = path.slice(0, -1)
    }
    return path;
  }

  return (
    <>
      <Location>

        {({ location }) => {
          return (
            <>
              {(location.pathname === '/') ?
                <>
                  {langu.map(ln => (
                    <MenuButton
                      key={ln.code}
                      onClick={() => i18n.changeLanguage(ln.code.toLowerCase())} >
                      <RegularTypo text={ln.name} />
                    </MenuButton>
                  ))}
                </>
                :
                <>
                  {langu.map(ln => (
                    <StyledLink
                      key={ln.code}
                      to={t(`slugs${ln.code}::${removeTrailingSlash(location.pathname)}`) + parameter}
                    >
                      <RegularTypo text={ln.name} />
                    </StyledLink>
                  ))}
                </>
              }
            </>
          )
        }}
      </Location>
    </>
  )
}

export default LanguageSwitcher