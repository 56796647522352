import React, { useState, useEffect } from 'react'
import * as JsSearch from 'js-search'
import { useStaticQuery, graphql } from 'gatsby'
import { realProducts, ProductsName } from '../utils/utils'
import { tychoNavy } from '../utils/theme'
import MenuContents from '../molecules/MenuBody/menuContents'
import CloseX from '../atoms/CloseX'
import SmallerTypo from '../typography/SmallerTypo'
import RegularTypo from '../typography/RegularTypo'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { germany } from '../initial/vars'
import { useTranslation } from 'react-i18next'
const slugify = require('slugify')

function Search() {

  const { t, i18n } = useTranslation()

  const dataSearch = useStaticQuery(graphql`
    query AllProducts2 {
      allWcProducts {
        edges {
          node {
            name
            short_description
            slug
            categories {
              name
            }
          }
        }
      }
    }
  `)

  const translateList = list => (
    list.map(element => {
      const translatedName = t(`product::${element.node.name}`)
      const translatedDescription = t(`${element.node.name}`)
      return {
        node: {
          ...element.node,
          name: translatedName,
          short_description: translatedDescription
        }
      }
    })
  )

  const [productList, setProductList] = useState({})
  const [search, setSearch] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search(['node', 'name'])
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(['node', 'name'])
    dataToSearch.addIndex(['node', 'name'])
    dataToSearch.addIndex(['node', 'short_description'])
    dataToSearch.addDocuments(productList)
    setSearch(dataToSearch)
  }

  useEffect(() => {
    setProductList(translateList(dataSearch.allWcProducts.edges.filter(realProducts)))
    rebuildIndex()
  }, [searchQuery])

  const searchData = e => {
    const queryResult = search.search(e.target.value)
    setSearchQuery(e.target.value)
    setSearchResults(queryResult)
  }

  const queryResults = searchQuery === '' ? productList : searchResults

  const StyledLink = styled.div`
  display: flex;
  padding-top: 16px;

  &:last-child {
    padding-bottom: 32px;
  }

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    transition: opacity 0.3s;
  }

  a:hover {
    opacity: 0.45;
  }
`

  const curretnLang = i18n.language
  const prefix = germany ? curretnLang + '/' : ''

  const slug = (name) => slugify(t(`product::${name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')


  return (
    <>
      <div style={{ position: 'relative' }}>
        <input
          value={searchQuery}
          onChange={searchData}
          placeholder={t('Szukaj produktów')}
          style={{
            border: `1px solid ${tychoNavy}`,
            margin: '16px',
            padding: '16px 32px 16px 16px',
            height: '48px',
            width: 'calc(100% - 32px)',
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
          }}
        />

        {productList.length !== queryResults.length && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              marginTop: '-8px',
              height: '16px',
              marginRight: '17px',
            }}
          >
            <CloseX onClick={() => setSearchQuery('')} />
          </div>
        )}
      </div>
      {productList.length !== queryResults.length && (
        <div style={{ padding: '0 16px 0 16px' }}>
          <SmallerTypo grey text={`${t('Znaleziono')}: ${queryResults.length}`} />

          {queryResults.length !== 0 &&
            queryResults.map(item => (
              <StyledLink key={item.node.slug}>
                <Link to={`/${prefix}${ProductsName(curretnLang)}/${slug(item.node.name)}/`}>
                  <RegularTypo text={item.node.name} />
                </Link>
              </StyledLink>
            ))}
        </div>
      )}

      {searchQuery.length === 0 && <MenuContents />}
    </>
  )
}

export default Search
