import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const StyledMask = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ white }) =>
    white ? 'rgba(0, 0, 0, 0.0)' : 'rgba(0, 0, 0, 0.45)'};
  opacity: 0.5;
  filter: alpha(opacity=45);
  transition: all 0.3s linear, height 0s ease 0.3s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1.0' : '0')};
`

const Wrapper = ({ children, onClick, visible }) => {
  const DOMRef = useRef(null)
  useEffect(() => {
    DOMRef.current.focus()
  }, [visible])

  return (
    <div
      ref={DOMRef}
      tabIndex="0"
      onKeyDown={e => e.key === 'Escape' && onClick()}
    >
      {children}
    </div>
  )
}

const Mask = ({ onClick, visible, white }) => {
  return (
    <Wrapper onClick={onClick} visible={visible}>
      <StyledMask onClick={onClick} visible={visible} white={white} />
    </Wrapper>
  )
}
export default Mask
