import React, { useState, useContext } from 'react'
import Collapse from 'rc-collapse'
import '../../additionalStyles/collapse.css'
import TabPanel from '../../molecules/MenuItems/TabPanel'
import SubMenu from '../../molecules/MenuItems/SubMenu'
import NestedSubMenu from '../../molecules/MenuItems/NestedSubMenu'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { countries, germany } from '../../initial/vars'
import { ProductsName, getCurrencySymbol } from '../../utils/utils'
import { breakpoints } from '../../utils/theme'
import RegularTypo from '../../typography/RegularTypo'
import { UsersContext } from '../../context'
import RegionMenu from '../../molecules/RegionMenu'
import { Link } from 'gatsby'

const slugify = require('slugify')

const StyledLink = styled(Link)`
 display: flex;
 padding: 16px;
 border-top: 1px solid rgb(223, 223, 223);
 text-decoration: none;
`

const RegionSelector = styled.button`
 cursor: pointer;
 padding: 0;
 border: 0;
 background: none;
`

const MenuWrapper = styled.div`
 display: flex;
 flex-direction: column;
 height: 100%;
`

const MenuBody = styled.div`
 height: 100%;
 z-index: 9999999;
`

const LangWrapper = styled.div`
 display: none;

 @media (max-width: ${breakpoints.mobile}px) {
  display: block;
 }

 padding: 24px 16px 80px;
`

const orphans = edges => edges.node.wordpress_parent === null
const catsRealProducts = edges => edges.node.name !== 'Bez kategorii'
const byImportance = (a, b) =>
 a.description > b.description ? 1 : b.description > a.description ? -1 : 0
const byMenuOrder = (a, b) => {
 return a.node.menu_order > b.node.menu_order
  ? 1
  : b.node.menu_order > a.node.menu_order
  ? -1
  : 0
}

var Panel = Collapse.Panel

const MenuContents = () => {
 const [regionMenu, setRegionMenu] = useState(false)

 const { setRegion, region } = useContext(UsersContext)

 const data = useStaticQuery(graphql`
  {
   allWcProductsCategories {
    edges {
     node {
      name
      wordpress_children {
       name
       description
      }
      wordpress_parent {
       name
      }
     }
    }
   }
   allWcProducts(filter: { stock_quantity: { ne: 0 } }) {
    edges {
     node {
      slug
      name
      status
      categories {
       name
      }
      menu_order
     }
    }
   }
  }
 `)

 const [activeKey, setActiveKey] = React.useState()
 const { t, i18n } = useTranslation()
 const curretnLang = i18n.language
 const prefix = germany ? curretnLang + '/' : ''

 return (
  <MenuWrapper>
   <MenuBody>
    {germany ? (
     <RegionMenu
      visible={regionMenu}
      setRegionMenu={setRegionMenu}
      setRegion={setRegion}
      countries={countries}
     />
    ) : null}

    {data.allWcProductsCategories.edges
     .filter(orphans)
     .filter(catsRealProducts)
     .map((edge, index) => (
      <TabPanel key={index} value={0} index={index}>
       {edge.node.wordpress_children.sort(byImportance).map((child, index) => (
        <Collapse
         key={index}
         onChange={activeKey => setActiveKey(activeKey)}
         activeKey={activeKey}
         accordion
        >
         {child.name === 'Pura' ? (
          <StyledLink
           to={
            `/${prefix}${ProductsName(curretnLang)}/` +
            slugify(t(`product::${child.name}`), {
             lower: true
            }).replace(/[^a-z0-9-]/gi, '')
           }
          >
           <RegularTypo text={t(`product::${child.name}`)} />
          </StyledLink>
         ) : (
          <Panel
           header={<SubMenu text={t(child.name)} />}
           key={child.name + edge.node.name}
          >
           <Collapse accordion>
            {data.allWcProductsCategories.edges
             .filter(edges => edges.node.name === child.name)
             .map((edge, index) => (
              <div key={index}>
               {edge.node.wordpress_children.sort(byImportance).map(child => (
                <Collapse key={child.name} accordion>
                 <Panel
                  key={child.name}
                  header={<SubMenu tiny text={t(child.name)} />}
                 >
                  {data.allWcProducts.edges
                   .filter(
                    edges =>
                     (edges.node.categories[0] &&
                      edges.node.categories[0].name === child.name) ||
                     (edges.node.categories[1] &&
                      edges.node.categories[1].name === child.name) ||
                     (edges.node.categories[2] &&
                      edges.node.categories[2].name === child.name) ||
                     (edges.node.categories[3] &&
                      edges.node.categories[3].name === child.name)
                   )
                   .sort(byMenuOrder)
                   .map(edge => (
                    <NestedSubMenu
                     text={t(`product::${edge.node.name}`)}
                     link={
                      `/${prefix}${ProductsName(curretnLang)}/` +
                      slugify(t(`product::${edge.node.name}`), {
                       lower: true
                      }).replace(/[^a-z0-9-]/gi, '')
                     }
                     key={edge.node.name}
                    />
                   ))}
                 </Panel>
                </Collapse>
               ))}
              </div>
             ))}
           </Collapse>
          </Panel>
         )}
        </Collapse>
       ))}
      </TabPanel>
     ))}
   </MenuBody>

   {germany && (
    <LangWrapper>
     <RegionSelector onClick={() => setRegionMenu(!regionMenu)}>
      <RegularTypo
       text={t(`checkout::${region}`) + ' | ' + getCurrencySymbol(region)}
      />
     </RegionSelector>
    </LangWrapper>
   )}
  </MenuWrapper>
 )
}

export default MenuContents
