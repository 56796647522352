import React from 'react'
import RegularTypo from '../../../typography/RegularTypo'
import { mainBackgroundGray } from '../../../utils/theme'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled.div`
  display: flex;
  padding: 0 12px 18px 14px;
  background: ${mainBackgroundGray};

  a {
    text-decoration: none;
    transition: opacity 0.3s;
    width: 100%;
    height: 100%;
    padding: 2px 0 0 2px;
  }

  a:hover {
    opacity: 0.45;
  }
`

export default function NestedSubMenu({ text, link }) {
  return (
    <StyledLink>
      <Link to={link}>
        <RegularTypo text={text} />
      </Link>
    </StyledLink>
  )
}
