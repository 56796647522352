import React from 'react'
import styled from 'styled-components'

const StyledMenuButton = styled.button`
  cursor: pointer;
  background: none;
  padding: 4px;
  font-size: 13px;
  line-height: 1.28571428571;
  color: #868686;
  border: none;
`

const MenuButton = ({ children, ...rest }) => {
  return (
    <StyledMenuButton {...rest} >
      {children}
    </StyledMenuButton>
  )
}

export default MenuButton
