export const mainBackgroundGray = '#F3F3F3'
export const typoLightGray = '#B3B3B3'
export const borderGray = '#DFDFDF'
export const tychoNavy = '#28293D'
export const tychoBlack = '#0F0F0F'
export const tychoGray = '#868686'
export const inactiveGray = '#A6A6A6'
export const glassGrey = '#d5d5d5'
export const superLight = 'rgba(0,0,0,.06)'
export const brightSky = 'rgba(63, 81, 181, 0.16)'
export const alertRed = '#f44336'
export const invalidPale = 'rgb(254, 240, 232)'



export const breakpoints = {
  mobile: 716,
  tablet: 1024,
  desktop: 1260,
}

export const maxWidth = 1620