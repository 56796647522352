import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../utils/theme'

const RegularTypoContainer = styled.div`
  ${({ color }) => `color: ${color || 'inherit'}`};
  ${({ oneline }) => oneline && `text-overflow: ellipsis`};
  ${({ oneline }) => oneline && `white-space: nowrap`};
  ${({ oneline }) => oneline && `overflow: hidden`};
  line-height: 1.5;
  font-size: 16px;

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
  }

  ul {
    margin: 0;
  }
  li {
    margin-bottom: 0;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  li:not(:nth-child(-n + 3)) {
    display: none;
  }

`
export default function RegularTypo({ text, style, oneline, color, className }) {
  return (
    <RegularTypoContainer
      className={className}
      oneline={oneline}
      style={style}
      color={color}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </RegularTypoContainer>
  )
}
