import React from 'react'
import styled from 'styled-components'
import Mask from '../../atoms/Mask'
import MenuButton from '../../atoms/MenuButton'
import RegularTypo from '../../typography/RegularTypo'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../components/langswitch'
import CloseX from '../../atoms/CloseX'

const Menu = styled.div`
  text-align: center;
  padding-top: 18px;
  background-color: white;
  width: 360px;
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  position: fixed;
  top: ${p => p.visible ? '50%' : '-50%'};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  overflow-y: auto;
  max-height: 100vh;
`

const CloseWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  padding-top: 10px;
`

const ButtonWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 24px;
`

const RegionMenu = ({ visible, setRegionMenu, setRegion, countries }) => {
  const { t } = useTranslation()

  const chooseRegion = (region) => {
    setRegion(region)
    // setRegionMenu(false)
  }


  return (
    <>
      <Mask onClick={() => setRegionMenu(false)} visible={visible} />
      <Menu visible={visible} >
        <CloseWrapper >
          <CloseX onClick={() => setRegionMenu(false)} />
        </CloseWrapper>

        <RegularTypo text={'Choose your shipping location'} />
        <ButtonWrap>
          {countries.map(country => <MenuButton key={country} onClick={() => chooseRegion(country)} >
            <RegularTypo text={t(`checkout::${country}`)} />
          </MenuButton>
          )}
        </ButtonWrap>

        <RegularTypo text={'Choose your language'} />
        <ButtonWrap>
          <LanguageSwitcher />
        </ButtonWrap>
      </Menu>
    </>
  )
}

export default RegionMenu
