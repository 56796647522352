import React from 'react'
import { useState, useEffect } from 'react'
import { germany } from '../initial/vars'
import i18next from 'i18next';

// export const eur2GBP = 1.093
export const eur2GBP = 1.16
export const eur2DKK = 8.15
// export const eur2SEK = 12.762
export const eur2SEK = 13.5
export const eur2CHF = 1.27

const toFixed = (val) => (val / 1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const windowGlobal = typeof window !== 'undefined' && window

export const repeatElement = (element, count) => Array(count).fill(element)

export function formatPriceMain({ price, region }) {

  switch (region) {
    case 'PL':
      return <>
        <span itemProp="price" content={(price / 1).toFixed(2)}>
          {toFixed(price)}
        </span>
        <span itemProp="priceCurrency" content='PLN'>
          {' '}zł
        </span>
      </>
    case 'CH':
      return <>
        <span itemProp="priceCurrency" content='CHF'>
          CHF&nbsp;
        </span>
        <span itemProp="price" content={toFixed(price * eur2CHF)}>
          {toFixed(price * eur2CHF)}
        </span>
      </>
    case 'UK':
      return <>
        <span itemProp="priceCurrency" content='GBP'>
          £
        </span>
        <span itemProp="price" content={toFixed(price * eur2GBP)}>
          {toFixed(price * eur2GBP)}
        </span>
      </>
    case 'DK':
      return <>
        <span itemProp="price" content={toFixed(price * eur2DKK)}>
          {toFixed(price * eur2DKK)}
        </span>
        <span itemProp="priceCurrency" content='DKK'>
          {' '}kr.
        </span>
      </>
    case 'SE':
      return <>
        <span itemProp="price" content={toFixed(price * eur2SEK)}>
          {toFixed(price * eur2SEK)}
        </span>
        <span itemProp="priceCurrency" content='SEK'>
          {' '}kr
        </span>
      </>
    default:
      return <>
        <span itemProp="price" content={(price / 1).toFixed(2)}>
          {toFixed(price)}
        </span>
        <span itemProp="priceCurrency" content='EUR'>
          {' '}€
        </span>
      </>
  }
}


export const getCurrencySymbol = (countrycode) => {
  switch (countrycode) {
    case 'PL':
      return 'zł';
    case 'CH':
      return 'CHF';
    case 'UK':
      return '£';
    case 'DK':
      return 'kr.';
    case 'SE':
      return 'kr';
    default:
      return '€';
  }
}


export function formatPriceReceived({ price, currency }) {

  switch (currency) {
    case 'PLN':
      return `${toFixed(price)} zł`;
    case 'CHF':
      return `CHF ${toFixed(price)}`;
    case 'GBP':
      return `£${toFixed(price)}`;
    case 'DKK':
      return `${toFixed(price)} kr.`;
    case 'SEK':
      return `${toFixed(price)} kr`;
    default:
      return `${toFixed(price)} €`;
  }

}

export const getCurrency = (region) => {

  switch (region) {
    case 'PL':
      return 'PLN'
    case 'UK':
      return 'GBP'
    case 'DK':
      return 'DKK'
    case 'SE':
      return 'SEK'
    case 'CH':
      return 'CHF'
    default:
      return 'EUR'
  }
}


export function exchange({ price, region }) {

  switch (region) {
    case 'PL':
      return toFixed(price);
    case 'CH':
      return toFixed(price * eur2CHF);
    case 'UK':
      return toFixed(price * eur2GBP);
    case 'DK':
      return toFixed(price * eur2DKK);
    case 'SE':
      return toFixed(price * eur2SEK);
    default:
      return toFixed(price);
  }

}


export function formatPrice({ price, region }) {

  switch (region) {
    case 'PL':
      return `${toFixed(price)} zł`;
    case 'CH':
      return `CHF ${toFixed(price * eur2CHF)}`;
    case 'UK':
      return `£${toFixed(price * eur2GBP)}`;
    case 'DK':
      return `${toFixed(price * eur2DKK)} kr.`;
    case 'SE':
      return `${toFixed(price * eur2SEK)} kr`;
    default:
      return `${toFixed(price)} €`;
  }

}

export const getNetto = (price, vatRate) => {
  const netto = price / vatRate
  return roundToTwoDecimals(netto)
}

export function priceToString(price) {
  return `${(price / 1).toFixed(2)}`
}

export const roundToTwoDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const price = (product, vatRate = 1) => {
  if (product && product.regular_price) {
    return (product.quantity * product.regular_price / vatRate)
  } else if (product) {
    return (product.quantity * product.price / vatRate)
  }
}


export const ProductsName = (lang) => {
  switch (lang) {
    case 'pl':
      return 'produkty'
    case 'en':
      return 'products'
    case 'de':
      return 'produkte'
    case 'nl':
      return 'producten'
    default:
      return 'products'
  }
}

export const usePersistedState = (key, defaultValue) => {
  const [state, setState] = useState(
    () =>
      (windowGlobal.localStorage &&
        JSON.parse(windowGlobal.localStorage.getItem(key))) ||
      defaultValue
  )
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])
  return [state, setState]
}

export const totalPrice = (product, vatRate) => {
  if (product && product.kitBase_S60) {
    const {
      damper,
      synchrrro,
      kitBase_S60,
      wallBracket45,
      wallBracket25,
      woodedFasciaBracket,
      aluFascia,
      aluFasciaSimple,
      track,
      spacer
    } = product

    return (
      price(kitBase_S60, vatRate) +
      price(wallBracket45, vatRate) +
      price(wallBracket25, vatRate) +
      price(woodedFasciaBracket, vatRate) +
      price(aluFascia, vatRate) +
      price(aluFasciaSimple, vatRate) +
      price(spacer, vatRate) +
      price(damper, vatRate) +
      price(synchrrro, vatRate) +
      price(track, vatRate)
    )
  } else {
    return price(product, vatRate)
  }
}

const onlyPositive = item => item.quantity > 0

export const simplify = cart => {
  const baza = []
  if (cart.length) {
    cart.map((item, index) => {
      const simple = cart && cart[index].readyProduct
      const { name, wpId, quantity, sku, ...simpleWithTracks } = simple
      return cart[index].readyProduct.price
        ? baza.push(simple)
        : baza.push(...Object.values(simpleWithTracks).filter(onlyPositive))
    })
  }
  return baza
}

export const removeTags = html => {
  if (windowGlobal) {
    var div = document.createElement('div')
    div.innerHTML = html
    return div.innerText
  }
}

// const onlyCustom = item => !item.readyProduct.product_id
export const onlySimple = item => item.readyProduct.product_id


export const createProd = cart => {
  const skuSuffinx = germany ? "_" + i18next.language.slice(0, -1) : ""

  const data = {
    create: [],
  }
  cart.forEach(item => {

    data.create.push({
      name: item.readyProduct.options
        ? `${i18next.t(`product::${item.readyProduct.name}`)} \n <h5>${item.readyProduct.options.map(option => [i18next.t(option[0]), i18next.t(option[1])]).join(
          ' <br /> '
        ).replace(/,/g, ': ')} <br /> ${item.readyProduct.sku} </h5>`
        : i18next.t(`product::${item.readyProduct.name}`),
      regular_price:
        item.readyProduct.price ||
        (totalPrice(item.readyProduct) / item.readyProduct.quantity).toString(),
      sku: item.readyProduct.sku.trim() + skuSuffinx,
    })
  })
  return data
}

const redirectUrl = germany ? "https://tycho.tech/order" : "https://tycho.pl/kasa"

export const orderQueryTest = (products, cart) => {
  // const simplifiedCart = cart.filter(onlySimple)
  const l = products ? products.length : 0
  // const sl = simplifiedCart.length
  // let cstm = `l=${l + sl}`
  let cstm = `l=${l}`
  products &&
    products.forEach((item, index) => {
      const id = item.error ? item.error.data.resource_id : item.id
      const qty = cart[index].readyProduct.quantity
      cstm += `&id${index + 1}=${id}&q${index + 1}=${qty}`
    })
  // simplifiedCart.forEach((item, index) => {
  //   const id = item.readyProduct.product_id
  //   cstm += `&id${index + 1 + l}=${id}&q${index + 1 + l}=${
  //     item.readyProduct.quantity
  //   }`
  // })
  return `${redirectUrl}/loading/?${cstm}`
}

export const fromMostExpensive = (b, a) => {
  if (germany) {
    return parseFloat(a.node.acf.euro_price) - parseFloat(b.node.acf.euro_price)
  } else {
    return parseFloat(a.node.price) - parseFloat(b.node.price)
  }
}

export const realProducts = edges =>
  edges.node.categories[0] && edges.node.categories[0].name !== 'Bez kategorii'

export const ChildrenWithProps = (children, data) =>
  React.Children.map(children, (child, i) => {
    return React.cloneElement(child, {
      data: data,
      index: i,
    })
  })

export const isTouchDevice = () => {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  )
}

export const isSlidingSystemCheck = id => id === 10 || id === 27 || id === 19
export const isSlidingSystemLCheck = id => id === 2447 || id === 2449
export const isSlidingSystemWCheck = id => id === 976 || id === 982 || id === 2488
export const isGlassSystemCheck = id => id === 545 || id === 555
export const isG80SystemCheck = id => id === 555
export const isFoldingSystemCheck = id => id === 68 || id === 74
export const isDoubleSystemCheck = id => id === 59 || id === 62 || id === 65
export const isSimpleFasciaCheck = id => [909, 911, 2508, 2510, 2272, 2506].includes(id)
export const isRegularTrackCheck = id => [933, 906, 2522].includes(id)
export const isSlidingSystemW50Check = id => id === 2559 || id === 2589

export const getFasciaSkuPrefix = id => {
  if (id === 909) {
    return '925.S'
  }
  if (id === 911) {
    return '945.S'
  }
  if (id === 2508) {
    return '937.S'
  }
  if (id === 2510) {
    return '957.S'
  }
  if (id === 2272) {
    return '980.S'
  }
  if (id === 2506) {
    return '992.S'
  }
}
export const getTrackSkuPrefix = id => {
  switch (id) {
    case 933:
      return 'F'
    case 906:
      return 'S'
    case 2522:
      return 'UNI'
    default:
      return null
  }
}


export const isConfigurableProduct = product => {
  if (
    (product.node.tags[0] && product.node.tags[0].name === 'gravity') ||
    (product.node.tags[1] && product.node.tags[1].name === 'gravity') ||
    (product.node.tags[2] && product.node.tags[2].name === 'gravity')
  ) {
    return true
  } else {
    return false
  }
}

export const parameterizedTrack = (num, isFoldingSystem, isDoubleSystem, isSlidingSystemW50) => {
  if (isFoldingSystem) {
    if (num > 200) {
      return 200
    }
    if (num < 80) {
      return 80
    }
    return num || 120
  } else if (isSlidingSystemW50) {
    if (num >= 250) {
      return 300
    }
    if (num < 250) {
      return 200
    }
    return num || 200
  } else if (isDoubleSystem) {
    if (num > 300) {
      return 300
    }
    if (num < 180) {
      return 180
    }
    return num || germany ? 200 : 240
  } else {
    if (num > 300) {
      return 300
    }
    if (num < 100) {
      return 100
    }
    return num || 180
  }
}


export const getShippingCost = ({
  country,
  lengthOver250
}) => {
  if (country === 'DE') {
    return 4.7
  } else if (lengthOver250) {
    return 25.9
  } else {
    return 13.9
  }
}
