import React from 'react'
import { borderGray } from '../../../utils/theme'
import styled from 'styled-components'

const StyledTabPanel = styled.div`
  border-bottom: 1px solid ${borderGray};
  ${({ isCentered }) => `text-align:  ${isCentered ? 'center' : 'left'}`};
`

export default function SubMenu({ value, index, children, isCentered }) {
  return (
    <StyledTabPanel isCentered={isCentered} hidden={value !== index}>
      {children}
    </StyledTabPanel>
  )
}
