import React from 'react'
import styled from 'styled-components'
import { inactiveGray } from '../utils/theme'

const SmallerTypoContainer = styled.div`
  font-size: 14px;
  line-height: 1.33333333333;
  ${({ oneline }) => oneline && `text-overflow: ellipsis`};
  ${({ oneline }) => oneline && `white-space: nowrap`};
  ${({ oneline }) => oneline && `overflow: hidden`};
  ${({ grey }) => grey && `color: ${inactiveGray}`};

  ul {
    margin: 0;
  }
  li {
    margin-bottom: 0;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  li:not(:nth-child(-n + 3)) {
    display: none;
  }
`
export default function SmallerTypo({ text, grey, style, oneline }) {
  return (
    <SmallerTypoContainer oneline={oneline} grey={grey} style={style}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </SmallerTypoContainer>
  )
}
